import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LLMVibeVisualizer from './components/LLMVibeVisualizer';
import BlogPage from './components/BlogPage';
import './App.css';

// This component handles redirects from the 404.html page
function RedirectHandler() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if we came from a redirect (has path in sessionStorage)
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (redirectPath) {
      // Clear it
      sessionStorage.removeItem('redirectPath');
      // Navigate to the path
      navigate(redirectPath);
    }
  }, [navigate]);
  
  return null;
}

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <RedirectHandler />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/visualizer" element={<LLMVibeVisualizer />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;